<template>
  <div id="app">
    <router-view />
    <b-loading :active="$store.state.loading"></b-loading>
  </div>
</template>

<script>
export default {
  name: 'App'
}
</script>