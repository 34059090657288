import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    loading: false,
    form: {
      name: null,
      pages: []
    }
  },
  mutations: {
    SET_PAGES(state, pages) {
      state.form.pages = pages
      state.loading = false
    },
    SET_LOADING(state, loading) {
      state.loading = loading
    }
  }
})
