import { initializeApp } from "firebase/app";
import { getStorage } from "firebase/storage";
import { getFirestore } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const app = initializeApp({
    apiKey: "AIzaSyBKGerV8MxTsJpQUfx9kVl2JnmH9gV18VU",
    projectId: "engineer-form-qaa",
    storageBucket: "engineer-form-qaa.appspot.com",
    messagingSenderId: "187050810522",
    appId: "1:187050810522:web:ac783310f6a50366831323",
    measurementId: "G-GLT48XB5NQ"
});

// const appCheck = 
initializeAppCheck(app, {
    provider: new ReCaptchaV3Provider('6LdJSmcfAAAAAEl1Ps7cOSwak5XYRU8KHVVDeKMt'),
    isTokenAutoRefreshEnabled: true
});

const storage = getStorage(app);
const analytics = getAnalytics(app);
const firestoreDb = getFirestore(app);

export { app, storage, firestoreDb, analytics }