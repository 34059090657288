import Vue from "vue";
import VueRouter from "vue-router";

Vue.use(VueRouter);

const routes = [
  {
    name: 'form',
    path: '/:id',
    component: () => import(/* webpackMode: "lazy" */ `@/pages/form`)
  },
  {
    path: '**',
    component: () => import(/* webpackMode: "lazy" */ `@/pages/index`)
  }
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;
